export enum AuthDataActionsTypes {
  LoginInitiated = '[Auth] Login Initiated',
  LoginSuccess = '[Auth] Login Success',
  LoginFailed = '[Auth] Login Failed',

  LogoutInitiated = '[Auth] Logout Initiated',
  LogoutSuccess = '[Auth] Logout Success',
  LogoutFailed = '[Auth] Logout Failed',

  HydrateTokens = '[Auth] HydrateTokens Initiated',
  HydrateTokensSuccess = '[Auth] HydrateTokens Success',
  HydrateTokensFailed = '[Auth] HydrateTokens Failed',

  RefreshTokens = '[Auth] RefreshTokens Initiated',
  RefreshTokensSuccess = '[Auth] RefreshTokens Success',
  RefreshTokensFailed = '[Auth] RefreshTokens Failed',

  NoUpdateNeeded = '[Auth] No Update Needed',
}
