import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { catchError, filter, map, Observable, tap, throwError } from 'rxjs';

import { AUTH_DATA_ACTIONS } from '../../../state/authentication/auth.actions';
import { LoginResponseModel } from '../../../feature/shared/models/login-response.model';
import { Memoized } from '@acetech-development/utilities/core';
import { Tokens } from '../../../feature/shared/models/auth-data.model';
import { AUTH_DATA_SELECTORS } from '../../../state/authentication/auth.selectors';

import { TokenDecodeModel } from '../../../feature/shared/models/token-decode.model';
import { jwtDecode } from 'jwt-decode';
import { UserRolesModel } from '../../domain/users/models/users.model';
import { ENVIRONMENT } from '../../../../../environments/environment';
import { CurrentUserModel } from '../../../feature/shared/models/user.model';
import { AppConfigurationService } from '../../../../services/app-configuration.service';
import { ChangePasswordDataModel } from '../change-password/change-password-request.model';
import { USER_ROLES } from '../../../feature/shared/constants/user-roles.const';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly http = inject(HttpClient);
  private readonly store = inject(Store);
  private readonly appConfigurationService = inject(AppConfigurationService);

  private readonly appUris = this.appConfigurationService.configuration.uris.authentication;
  private readonly adminAppUris = this.appConfigurationService.configuration.uris.admin;

  public login(username: string, password: string): Observable<LoginResponseModel> {
    const url = this.appUris.login();
    const authData = { username, password };
    return this.http.post<LoginResponseModel>(url, authData);
  }

  public changePassword(changePasswordData: ChangePasswordDataModel): Observable<CurrentUserModel> {
    const url = this.adminAppUris.changePassword();
    return this.http.post<CurrentUserModel>(url, changePasswordData);
  }

  public refreshToken(refreshToken: string): Observable<Tokens> {
    const url = this.appUris.refreshToken();
    const headers = new HttpHeaders({
      'X-API-KEY': ENVIRONMENT.configuration.uris.admin.apiKey,
    });
    return this.http.post<Tokens>(url, { refreshToken }, { headers }).pipe(
      tap((tokens: Tokens) => {
        this.store.dispatch(
          AUTH_DATA_ACTIONS.RefreshTokens({
            token: tokens.token,
            refreshToken: tokens.refreshToken,
          }),
        );
      }),
      catchError((error: Error) => {
        this.store.dispatch(AUTH_DATA_ACTIONS.RefreshTokensFailed());
        return throwError(() => error);
      }),
    );
  }

  public logout(): void {
    this.store.dispatch(AUTH_DATA_ACTIONS.Logout());
  }

  public isTokenExpired(tokenExpiration: string): boolean {
    return new Date().getTime() >= new Date(JSON.parse(tokenExpiration) as Date).getTime();
  }

  public getUserData(authData: LoginResponseModel): CurrentUserModel {
    const tokenData: TokenDecodeModel = jwtDecode(authData.token);
    return {
      userName: tokenData.userName,
      exp: tokenData.exp,
      iat: tokenData.iat,
      id: tokenData.id,
      requiresPasswordChange: tokenData.requiresPasswordChange,
      roles: this.getUserRoles(tokenData.roles),
      email: tokenData.email,
    };
  }

  public getUserRoles(roles: string[]): UserRolesModel {
    return {
      isSuperAdmin: roles.includes(USER_ROLES.SUPER_ADMIN),
      isAdmin: roles.includes(USER_ROLES.ADMIN),
      isOperator: roles.includes(USER_ROLES.OPERATOR),
    };
  }

  @Memoized
  public get isAuthenticated$(): Observable<boolean> {
    return this.store.select(AUTH_DATA_SELECTORS.selectIsAuthenticated);
  }

  @Memoized
  public get userData$(): Observable<CurrentUserModel> {
    return this.store.select(AUTH_DATA_SELECTORS.selectUserData).pipe(
      filter(Boolean),
      map((userData) => userData),
    );
  }
}
