import { createAction, props } from '@ngrx/store';
import { CurrentUserModel } from '../../feature/shared/models/user.model';
import { AuthDataActionsTypes } from './auth.action.types';

export const AUTH_DATA_ACTIONS = {
  Login: createAction(
    AuthDataActionsTypes.LoginInitiated,
    props<{
      isAuthenticated: boolean;
      token: string;
      refreshToken: string;
      userData: CurrentUserModel;
    }>(),
  ),
  LoginSuccess: createAction(
    AuthDataActionsTypes.LoginSuccess,
    props<{
      isAuthenticated: boolean;
      token: string;
      refreshToken: string;
      tokenExpiration: string;
      refreshTokenExpiration: string;
      userData: CurrentUserModel;
    }>(),
  ),
  LoginFailed: createAction(AuthDataActionsTypes.LoginFailed),

  Logout: createAction(AuthDataActionsTypes.LogoutInitiated),
  LogoutSuccess: createAction(
    AuthDataActionsTypes.LogoutSuccess,
    props<{
      isAuthenticated: boolean;
      token: string | undefined;
      refreshToken: string | undefined;
      tokenExpiration: string | undefined;
      refreshTokenExpiration: string | undefined;
      userData: CurrentUserModel | undefined;
    }>(),
  ),
  LogoutFailed: createAction(AuthDataActionsTypes.LogoutFailed),

  HydrateTokens: createAction(AuthDataActionsTypes.HydrateTokens),
  HydrateTokensSuccess: createAction(
    AuthDataActionsTypes.HydrateTokensSuccess,
    props<{
      token: string;
      refreshToken: string;
      tokenExpiration: string;
      refreshTokenExpiration: string;
      isAuthenticated: boolean;
      userData: CurrentUserModel;
    }>(),
  ),
  HydrateTokensFailed: createAction(AuthDataActionsTypes.HydrateTokensFailed),

  RefreshTokens: createAction(
    AuthDataActionsTypes.RefreshTokens,
    props<{
      token: string;
      refreshToken: string;
    }>(),
  ),
  RefreshTokensSuccess: createAction(
    AuthDataActionsTypes.RefreshTokensSuccess,
    props<{
      token: string;
      refreshToken: string;
      tokenExpiration: string;
      refreshTokenExpiration: string;
    }>(),
  ),
  RefreshTokensFailed: createAction(AuthDataActionsTypes.RefreshTokensFailed),

  noUpdateNeeded: createAction(AuthDataActionsTypes.NoUpdateNeeded),
};
